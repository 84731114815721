import { useEffect, useState } from 'react';
import './App.css';
import FipList from './Components/FipList';
import { getMetaData } from './Services/ConsentService';
import { MetaDataResponse } from './Types/ResponseTypes';
import { SESSIONOBJECTTYPE } from './Types/types';
import { pushJourneyFailedEvent } from './Utils/eventUtils';

export let SESSIONOBJECT: SESSIONOBJECTTYPE = {
  TENANTID: null,
  API_KEY: null,
  FIP_ID: null,
  JOURNEY_TYPE: null,
  TRACKINGID: null,
  GROUPID: null,
  AVAILABLEJOURNEYS: [],
  FIP_IDS: [],
};
function App() {
  const [orgId, setOrgId] = useState<string | undefined>(undefined);

  const getAndApplyCustomStyle = async () => {
    try {
      const metaDataResponse = await getMetaData();
      if (!metaDataResponse.ok) {
        throw 'Meta API:Something Went Wrong';
      }
      const metaDataResponseBody: MetaDataResponse = JSON.parse(
        await metaDataResponse.text()
      );
      SESSIONOBJECT.TENANTID = metaDataResponseBody.tenantId;
      SESSIONOBJECT.API_KEY = metaDataResponseBody.accessToken;
      SESSIONOBJECT.GROUPID = metaDataResponseBody.groupId;
      SESSIONOBJECT.FIP_IDS = metaDataResponseBody.fipIds;
      let linkTag = document.createElement('link');
      linkTag.setAttribute('rel', 'stylesheet');
      linkTag.setAttribute(
        'href',
        '/customStyles/' + SESSIONOBJECT.TENANTID + '.css'
      );
      linkTag.setAttribute('id', 'styles');
      document.body.append(linkTag);
      setOrgId(SESSIONOBJECT.TENANTID);
    } catch (e: any) {
      pushJourneyFailedEvent();
    }
  };
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const trackingId = searchParams.get('trackingId');
    SESSIONOBJECT.TRACKINGID = trackingId;
    getAndApplyCustomStyle();
  };
  useEffect(() => {
    getQueryParams();
  }, []);
  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty(
      '--doc-height',
      `${
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      }px`
    );
  };
  window.addEventListener('resize', () => documentHeight());
  documentHeight();
  return (
    <div className='App'>
      {orgId !== undefined ? (
        <>
          <div className='appNavBar'>
            <img
              alt=''
              height={40}
              src={`https://logo.ignosis.ai/${orgId}.jpeg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  'https://logo.ignosis.ai/PIRIMIDPREPROD_FIU_UAT.jpeg';
              }}
            />
          </div>
          <FipList />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;
