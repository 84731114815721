import { EVENTCODE } from '../Constants/constants';

const pushEventToReactNativeParent = (eventCode: string, error?: string) => {
  if ((window as any)?.ReactNativeWebView) {
    (window as any)?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        eventCode: eventCode,
        error: error,
      })
    );
  } else {
    window.parent.postMessage({ eventCode: eventCode, error: error }, '*');
  }
};
export const pushJourneyCompletedEvent = (e?: string) => {
  pushEventToReactNativeParent(EVENTCODE.JOURNEY_COMPLETED, e);
};
export const pushJourneyFailedEvent = (e?: string) => {
  pushEventToReactNativeParent(EVENTCODE.JOURNEY_FAILED, e);
};
export const pushAAJourneySelected = () => {
  pushEventToReactNativeParent(EVENTCODE.AA_JOURNEY_SELECTED);
};
export const pushNetBankingJourneySelected = () => {
  pushEventToReactNativeParent(EVENTCODE.NETBANKING_JOURNEY_SELECTED);
};
export const pushNetBankingGoBack = () => {
  pushEventToReactNativeParent(EVENTCODE.NETBANKING_JOURNEY_GO_BACK);
};
export const pushPDFJourneySelected = () => {
  pushEventToReactNativeParent(EVENTCODE.PDF_JOURNEY_SELECTED);
};
export const pushPDFGoBack = () => {
  pushEventToReactNativeParent(EVENTCODE.PDF_JOURNEY_GO_BACK);
};
